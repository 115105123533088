import React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import Awards from "components/ui/extended/Awards";
import BookADemo from "components/ui/extended/BookADemo";
import PlainHeader from "components/ui/base/PlainHeader";
import { getLocalStorageItem } from "utils/local-storage";
import { DEMO_NAME_KEY } from "constants/local-storage";

interface IndexPageProps {
  location: Location;
}

export default ({ location }: IndexPageProps) => {
  const { t } = useTranslation();

  const existingName =
    (typeof window !== "undefined" && getLocalStorageItem(DEMO_NAME_KEY)) || "";

  return (
    <GlobalWrapper
      withLayout
      location={location}
      layoutSettings={{
        withMenu: false,
        withFooter: false,
        clickableLogo: false,
        withCta: false,
        mobileCta: false,
      }}
    >
      <SEO
        title={t("pages.index.seo.title")}
        description={t("pages.index.seo.description")}
        withoutTitleSuffix={true}
        image={{
          relativePath:
            "meta/customerly-behavioural-chats-emails-and-surveys-saas.jpg",
          alt: "Customer Operating System for SaaS",
        }}
      />

      <PlainHeader
        title={t("pages.demo.title")}
        description={t("pages.demo.description")}
      />

      <BookADemo />
      <Awards />
    </GlobalWrapper>
  );
};
